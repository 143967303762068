<template>
  <b-overlay :show="loading">
    <b-card>
      <b-row>
        <b-col sm="12" md="3">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4">
          <b-form-group
            :label="$t('Sort')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- table -->
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="pengembalians"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(checkbox)>
              <b-form-checkbox v-model="allChecked" />
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox v-model="checkedPengembalian" :value="item" />
            </template>
            <template #cell(order)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tanggal)="{item}">
              {{ humanDate(item.tanggal) }}
            </template>
            <template #cell(aksi)="{ item }">
              <div>
                <b-button
                  size="sm"
                  @click.prevent="viewDetail(item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  size="sm"
                  @click.prevent="printPengembalian(item.id)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>
                <b-button
                  v-if="isSales"
                  size="sm"
                  @click.prevent="remove(item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
        <!-- / -->
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
  import {
    BOverlay,
    BFormCheckbox,
    BPagination,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
  } from "bootstrap-vue";
  import mixin from './mixin'
  export default {
    mixins: [mixin],
    components: {
      BOverlay,
      BFormCheckbox,
      BButton,
      BPagination,
      BCard,
      BRow,
      BCol,
      BTable,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BFormSelect,
    },
    props: ["active"],
    computed: {
      gudangKendaraanId() {
        return this.$route.params.id;
      },
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      }
    },
    data: () => ({
      allChecked: false,
      checkedPengembalian: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      loading: false,
      pengembalians: [],
      fields: [
        { key: "order", label: "No" },
        { key: "no", label: "No. Pengembalian" },
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "sales.nama_lengkap", label: "Sales" },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        // {key: 'rincian', label: 'Jumlah Barang Direquest'},
        { key: "aksi", label: "#" },
      ],
      swalOptions: {
        title: `Anda Yakin?`,
        text: `Request Pengembalian Stok Barang Akan Dihapus?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      },
    }),
    watch: {
      "$store.state.pengembalianbarang.isTabActive": {
        handler: function(val) {
          if(val) {
            this.getReturData()
          }
        },
        deep: true
      },
    },
    methods: {
      async printPengembalian(id) {
        try {
          this.loading = true
          const response = await this.print(id)
          this.loading = false
          await window.open(URL.createObjectURL(response))
          this.loading = false
        }
        catch(e) {
          this.loading = false
          this.displayError(e)
          return false
        }
      },
      viewDetail(item) {
        this.$router.push(`/pengembalian/detail/${item.id}`)
      },
      remove(item) {
        this.$swal(this.swalOptions).then(async (res) => {
          if (res.value) {
            try {
              this.loading = true;
              await this.$store.dispatch("pengembalianbarang/save", [{ id: item.id, fungsi: 2 }]);
              await this.getReturData();
              this.loading = false;
            } catch (e) {
              this.loading = false;
              this.displayError(e);
              return false;
            }
          }
        });
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getReturData() {
        const params = {order:"desc"};
        if (this.isSales) {
          params.id_sales = this.user.karyawan.id;
          params.id_gudang_m = this.myGudang.id
        }
        if (this.isAdminGudang && !this.isSales){
          params.id_gudang = this.myGudang.id
        }
        this.loading = true;
        this.pengembalians = await this.$store.dispatch("pengembalianbarang/getData", params);
        
        this.totalRows = this.pengembalians.length;
        this.loading = false;
      },
    },
    created() {
      this.getReturData();
    },
};
</script>
